<template>
  <div class="tab-menu">
    <nav class="tab-menu__wrapper">
      <ul class="tab-menu__list">
        <li
          v-for="(item, index) in tabData"
          :key="index"
          :class="$route.name === item.link ? 'active' : ''"
        >
          <router-link :to="{ name: item.link }">
            {{ item.title }}
          </router-link>
        </li>
      </ul>
    </nav>
  </div>
</template>

<script>
export default {
  name: "TabMenu",
  props: {
    tabData: {
      type: Array,
    },
  },
};
</script>

<style lang="scss">
.tab-menu {
  overflow-x: auto;
  &::-webkit-scrollbar {
    height: 0;
  }
  scrollbar-height: none;
  scrollbar-width: none;
  &__list {
    display: flex;
    gap: 50px;
    li {
      &.active {
        a {
          color: var(--black2);
        }
      }
      a {
        color: var(--btn-gray-color1);
        font-weight: 600;
        font-size: 30px;
        line-height: 45px;
        transition: all 0.4s ease-in-out;
        white-space: nowrap;
        &:hover {
          color: var(--btn-blue-color1);
        }
      }
    }
  }
  @include adaptive(phone) {
    &__list {
      gap: 15px;
      li {
        a {
          font-size: 16px;
          line-height: 18px;
        }
      }
    }
  }
}
</style>
