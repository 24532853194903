<template>
  <div class="lk">
    <div class="lk__content">
      <div class="container">
        <div
          class="lk__title"
          :class="$route.name === 'LkAbout' ? 'lk__title--about' : ''"
        >
          <div class="lk__tabs">
            <TabMenu :tab-data="tabsArray" />
            <button @click="logOut">Выход</button>
          </div>
        </div>
      </div>
      <div class="lk__content">
        <router-view></router-view>
      </div>
    </div>
  </div>
</template>

<script>
import TabMenu from "@/components/Blocks/TabMenu";
import { createNamespacedHelpers } from "vuex";
const { mapState } = createNamespacedHelpers("user");
export default {
  name: "LK",
  components: { TabMenu },
  data() {
    return {
      lkTabs: [
        {
          link: "LkAbout",
          title: "О себе",
        },
        {
          link: "LkRequest",
          title: "Подать заявку",
        },
        {
          link: "LkMyRequests",
          title: "Мои заявки",
        },
        // {
        //   link: "LkTasks",
        //   title: "Задания",
        // },
      ],
      lkTabsStart: [
        {
          link: "LkAbout",
          title: "О себе",
        },
      ],
    };
  },
  computed: {
    tabsArray() {
      return !!this.user.telegram_link ||
        !this.user.first_name ||
        !this.user.last_name
        ? this.lkTabsStart
        : this.lkTabs;
    },
    ...mapState(["user"]),
  },
  methods: {
    logOut() {
      this.$store.commit("user/killUser");
    },
  },
};
</script>

<style lang="scss">
.lk {
  &__title {
    margin-top: 50px;
    margin-bottom: 40px;
    button {
      font-size: 20px;
      line-height: 30px;
      color: var(--red);
      &:hover {
        -webkit-text-stroke-width: 0.5px;
      }
    }
  }
  &__tabs {
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 50px;
    overflow-x: auto;
    padding-bottom: 5px;
    &::-webkit-scrollbar {
      width: 0;
    }
    scrollbar-width: none;
  }
  @include adaptive(tablet-small) {
    &__title {
      &--about {
        margin: 0 -20px;
        padding: 30px 0;
        background-color: var(--btn-blue-color1);
        .tab-menu__list {
          li {
            &.active {
              a {
                color: var(--white);
              }
            }
            a {
              color: #d1d1d1;
              &:hover {
                color: var(--white);
              }
            }
          }
        }
        .lk__tabs {
          margin: 0 20px;
        }
      }
    }
  }
  @include adaptive(phone) {
    &__tabs {
      gap: 15px;
      button {
        font-size: 16px;
        line-height: 18px;
      }
    }
  }
}
</style>
